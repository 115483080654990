@use "./../../styles/mq" as *;

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 var(--spacing-core-4);
    margin: 0;

    @include mq($from: phablet) {
        .root {
            width: 242px;
            padding: 0 var(--spacing-core-8);
        }
    }

    .title {
        padding: 0;
        align-self: baseline;

        @include mq($from: phablet) {
            .title {
                padding-left: var(--spacing-core-4);
            }
        }
    }

    &:last-of-type {
        a:last-of-type {
            color: var(--color-feedback-error-default);
        }
    }
}

