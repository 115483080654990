.breadcrumbWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: var(--spacing-core-10) auto 0;

  .breadcrumbs {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-core-4);

    .breadcrumb {
      display: flex;
      align-items: center;

      .link {
        padding-right: var(--spacing-core-2);
      }

      .arrow {
        color: var(--color-foreground-primary-disabled);
        margin-right: var(--spacing-core-2);
      }
    }
  }
}
