@use "./styles/mq" as *;

.menuWrapper {
    width: 100%;
    max-width: 1114px;
    margin: 0 auto;
    padding: 0;
}

.root {
    display: flex;
    flex-direction: row;
    margin: 0 auto var(--spacing-core-22);
    max-width: 100%;

    @include mq($to: phablet) {
        flex-direction: column;
    }

    .wrapper {
        width: 100%;

        @include mq($from: tablet) {
            width: 242px;
            margin: 0 var(--spacing-core-20) 0 0;
        }
    }

    .content {
        margin: 0 auto;
    }
}
