.root {
    height: fit-content !important;
    width: 788px;
    gap: 0;
    color: var(--color-foreground-primary-default);
    border: var(--border-width-core-regular) solid
        var(--color-background-tertiary-default);
    border-radius: var(--border-radius-core-m);
    padding: var(--spacing-core-8) var(--spacing-core-6);
    justify-content: space-between;

    .content {
        display: flex;
        align-items: center;
        gap: var(--spacing-core-4);

        .text {
            display: flex;
            flex-direction: column;

            .subTitle {
                font-weight: lighter;
                color: var(--color-foreground-secondary-default);
            }
        }
    }

    &:hover {
        color: inherit;
    }
}

.root + .root {
    margin-top: var(--spacing-core-4);
}
