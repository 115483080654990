@use "../../../../styles/mq" as mq;

.root {
    height: fit-content !important;
    width: 210px;
    gap: var(--spacing-core-4);
    justify-content: flex-start;
    padding: var(--spacing-core-6) var(--spacing-core-8) var(--spacing-core-6) 0;
    border-bottom: var(--border-width-core-regular) solid
        var(--color-foreground-tertiary-default);
    color: var(--color-foreground-primary-default);

    .text {
        font-weight: normal;
    }

    .icon {
        min-width: var(--sizing-core-6);
        min-height: var(--sizing-core-6);
    }

    &.selected,
    &:hover {
        color: var(--color-action-primary-default);
        text-decoration: none;

        .text {
            font-weight: bold;
        }
    }
}

@include mq.mq($to: phablet) {
    .root {
        width: 100%;
    }
}
